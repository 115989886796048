<template>
  <div class="popup-container" :ref="reference">
    <div class="popup-bubble pa-4 overflow-hidden">
      <div v-if="type === 'start' && !disableEta" class="eta-container mr-3 rounded-circle">
        {{  eta  }}
        <span class="eta-mins">mins</span>
      </div>
      <div v-if="address" class="popup-address">
        <p v-if="type == 'start'" class="mb-2">{{$t('rideTracking.summary.booking.pick-up') }}</p>
        <p v-if="type == 'via'" class="mb-2">{{ $t('rideTracking.summary.booking.via') }}</p>
        <p v-if="type == 'end'" class="mb-2">{{ $t('rideTracking.summary.booking.drop-off') }}</p>
        <div class="description-address mb-0">{{ address }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { iconLoader } from '../../helpers/iconsLoader';

export default {
  name: 'BookingMarker',
  components: {
    ...iconLoader.booking,
  },
  props: {
    type: {
      type: String,
    },
    reference: {
      type: String,
    },
    address: {
      type: String,
    },
    color: {
      type: String,
    },
    textColor: {
      type: String,
      default: 'white',
    },
    eta: {
      type: Number,
      default: null,
    },
    disableEta: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
  .popup-bubble {
    width: 300px;
    display: flex;
    text-align: left;
    font-size: 16px;
    line-height: 20px;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #888888;
    }

    .description-address {
      display: block;
      word-wrap: break-all;
      word-break: break-all;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #333333;
    }
  }

  .tag {
    font-size: 36px;
    border-radius: 3px 0 0 3px;
    padding: 2px;
    width: 36px;
  }

  .popup-address {
    max-width: 60vw;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Inter';
  }

  .eta-container {
    font-family: 'Inter';
    margin: 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.15px;
    color: #ffffff;

    background-color: #47D061;
    min-width: 56px;
    height: 56px;

    & .eta-mins {
      color: #ffffff;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 0.1px;
      text-transform: uppercase;
    }
  }

  .popup-container {
    position: relative;
    text-align: center;

    svg {
      position: absolute;
      left: 45.7%;
    }
  }

  /* This element draws the tip. */
  .popup-bubble-anchor {
    content: "";
    width: 10px;
    height: 10px;
    border-width: 6px 1px;
    z-index: 12000;
    margin: 0 auto;
    background: white;
    transform: rotateY(0deg) rotate(45deg);
    transition: transform 2s;
    position: absolute;
    left: 48%;
    bottom: -5px;
  }
</style>
