<template>
  <l-map ref="map" :zoom="zoom" :center="center" style="height: 100%">
    <l-tile-layer :url="url" :attribution="attribution"/>
    <l-marker
      ref="pickupMarker"
      v-if="pickupLatLng !== null && iconSize !== null"
      :lat-lng="pickupLatLng"
      :draggable="pickupDraggable"
      @dragend="pickupUpdate"
    >
      <l-icon
        :icon-size="iconSize"
        :icon-anchor="iconAnchor"
        class-name="pickup-marker">
        <booking-marker
          reference="pickup"
          type="start"
          :color="colorPickupMarker"
          :address="pickup.description">
        </booking-marker>
      </l-icon>
    </l-marker>

    <l-marker
      :lat-lng="destinationLatLng"
      v-if="destinationLatLng !== null && iconSize !== null">
      <l-icon
        :icon-size="iconSize"
        :icon-anchor="iconAnchor"
        class-name="destination-marker">
        <booking-marker
          reference="pickup"
          type="end"
          :color="colorDestinationMarker"
          :address="destination.description">
        </booking-marker>
      </l-icon>
    </l-marker>

    <l-marker
      v-if="iconSize !== null"
      v-for="(waypoint, index) in waypointsLatLng"
      :key="index" :lat-lng="waypoint">
      <l-icon
        :icon-size="[26,46]"
        :icon-anchor="[13,46]"
        class-name="waypoint-marker">
        <booking-marker
          :reference="`waypoint-${index}`"
          type="via"
          :color="colorWaypointMarker"
          text-color="black">
        </booking-marker>
      </l-icon>
    </l-marker>

    <l-marker :lat-lng="driverPosition" v-if="driverPosition !== null" :icon="taxiIcon"></l-marker>
  </l-map>
</template>

<script>
import { mapGetters } from 'vuex';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';
import { icon } from 'leaflet';
import BookingMarker from './BookingMarker.vue';
import coordinates from './mixins/coordinates';

export default {
  name: 'Leaflet',
  mixins: [coordinates],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    BookingMarker,
  },
  props: {
    pickup: Object,
    waypoints: Array,
    destination: Object,
    driverLatLng: Object,
    pickupDraggable: Boolean,
    defaultCenterLatLng: Object,
    taxiIconUrl: String,
    colorPickupMarker: String,
    colorDestinationMarker: String,
    colorWaypointMarker: String,
  },
  data() {
    return {
      map: null,
      loading: false,
      show: true,
      enableTooltip: true,
      zoom: 13,
      fillColor: '#e4ce7f',
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      taxiIcon: null,
    };
  },

  methods: {
    latLngToProviderFormat(latLng) {
      return [latLng.latitude, latLng.longitude];
    },
    pickupUpdate(event) {
      const pickupCoordinates = {
        longitude: event.target._latlng.lng,
        latitude: event.target._latlng.lat,
      };
      this.$emit('pickupUpdate', pickupCoordinates);
    },
    fitBounds() {
      if (this.$refs.map) {
        const bounds = [];
        if (this.center) bounds.push(this.center);
        if (this.pickupLatLng) bounds.push(this.pickupLatLng);
        if (this.destinationLatLng) bounds.push(this.destinationLatLng);
        if (this.driverPosition) bounds.push(this.driverPosition);
        if (this.waypointsLatLng && this.waypointsLatLng.length > 0) {
          this.waypointsLatLng.forEach((waypoint) => {
            bounds.push(waypoint);
          });
        }

        if (bounds.length > 0) this.$refs.map.fitBounds(bounds);
      }
    },
  },
  computed: {
    ...mapGetters({
      maps: 'bookingChannel/maps',
      mapStyle: 'bookingChannel/mapStyle',
    }),
    iconSize() {
      if (this.map) {
        return [this.$refs.map.$el.clientWidth * 0.4, 46];
      }
      return null;
    },
    iconAnchor() {
      if (this.map) {
        return [this.$refs.map.$el.clientWidth * 0.2, 46];
      }
      return null;
    },
  },
  updated() {
    if (this.loading) return;
    this.map = this.$refs.map;
    this.fitBounds();
  },
  created() {
    if (this.taxiIconUrl) {
      this.taxiIcon = icon({
        iconUrl: this.taxiIconUrl,
        iconSize: [72, 72],
      });
    }
  },
};
</script>
